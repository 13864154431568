import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { fetchApi } from "helpers/reactQueryApi";

type Props = {
  externalId?: string;
  onChangeExternalId: (value: string) => void;
  title?: string;
  onChangeTitle: (value: string | undefined) => void;
};

function CustomSpvExternalIdInput({
  externalId,
  onChangeExternalId,
  title,
  onChangeTitle,
}: Props) {
  const { isLoading, data } = useQuery("todos", () =>
    fetchApi(`/custom/spv/rundschreiben_nummer`)
  );

  useEffect(() => {
    if (data) {
      onChangeExternalId(data.next_rs_number);
    }
  }, [!isLoading, data]);

  if (!data) {
    return <p>Lade aktuelle Rundschreibennummer...</p>;
  }

  return (
    <div className="flex flex-col gap-2 mt-4">
      <label>Titel</label>
      <input
        type={"text"}
        id={`${externalId}/title`}
        value={title}
        onChange={(e) => onChangeTitle(e.target.value)}
      />
      <label>Rundschreibennummer (SPV)</label>
      <input type={"text"} disabled defaultValue={data.next_rs_number || ""} />
    </div>
  );
}

export default CustomSpvExternalIdInput;
